import React, { useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import useStyles from './styles';
import BtnSlider from './BtnSlider';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Language } from '@mui/icons-material';

const Slider = ({items, language}) => {

    const classes = useStyles();

    const [slideIndex, setSlideIndex] = useState(1);

    const nextSlide = () => {
        if(slideIndex !== items.length){
            setSlideIndex(slideIndex+1);
        }else if(slideIndex === items.length){
            setSlideIndex(1)
        }
    }

    const prevSlide = () => {
        if(slideIndex !== 1){
            setSlideIndex(slideIndex - 1)
        }else if(slideIndex === 1){
            setSlideIndex(items.length);
        }
    }

    const moveDot = (index) => {
        setSlideIndex(index);
    }

    return(
    <>
    <Box className={classes.container}>
        {items.map((obj, index) => {
            return(
            <Box key={index} className={slideIndex === index + 1 ? classes.imgContainer1 : classes.imgContainer}>
                <GatsbyImage image={getImage(obj.localFile.childImageSharp)} className={classes.img} alt={Language.english ? obj.altText : obj.altTextIrish.irishAlt} />
            </Box>
            )
        })}
        <BtnSlider moveSlide={nextSlide} direction="next" />
        <BtnSlider moveSlide={prevSlide} direction="prev" />
        <Box className={classes.containerDots}>
        {Array.from({length: items.length}).map((item, index) => (
            <IconButton aria-label={`carousel item ${index+1}`} style={{height:'50px'}} focusVisibleClassName={classes.iconButton} onClick={() => moveDot(index+1)}>
                <Box key={index} style={{border:'1px solid black'}}  className={slideIndex === index+1 ? classes.dotActive : classes.dot}/>
            </IconButton>
        ))}
        </Box>
    </Box>
    <Box mt={2} ml={4}>
    {items.map((item, index) => (
        <Typography style={{display:slideIndex-1 === index ? 'unset' : 'none', fontWeight:500, fontSize:'14px', lineHeight:'20px'}}>{language.english ? slideIndex-1 === index &&  item.altTextIrish.imageCaption : slideIndex-1 === index &&  item.altTextIrish.imageCaptionIrish}</Typography>
    ))}
    </Box>
    </>
    )
}

export default Slider;