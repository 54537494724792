import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    img:{
        width:'100%',
    },
    iconButton:{
        border:'3px solid white'
    },
    container:{
        maxWidth: '100%', 
        height:'750px',
        margin: '40px auto 0', 
        position: 'relative', 
        overflow: 'hidden',
        [theme.breakpoints.up('xl')]: {
            height: '1000px',
          },
          [theme.breakpoints.down('lg')]: {
            height: '550px',
          },
          [theme.breakpoints.down('md')]: {
            height: '360px',
          },
          [theme.breakpoints.down('sm')]: {
            height: '265px',
          },
         
    },
    imgContainer:{
        width: '100%', 
        height: '100%', 
        position: 'absolute', 
        opacity: '0', 
        transition: 'opacity ease-in-out 0.4s'
    },
    imgContainer1:{
        width: '100%', 
        height: '100%', 
        position: 'absolute', 
        opacity: '1', 
        transition: 'opacity ease-in-out 0.4s'
    },
    btnSliderNext:{
        height: '65px',
        width:'65px',
        borderRadius: '50%',
        background: 'black',
       
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        top: '50%',
        right: '20px',
        transform: 'translateY(-60%)',
        opacity:0.7,
        "&:hover":{
            background:'#727272',
            opacity:1
        }
    },
    btnSliderPrev:{
        height: '65px',
        width:'65px',
        borderRadius: '50%',
        background: 'black',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        top: '50%',
        left: '20px',
        transform: 'translateY(-60%)',
        opacity:0.7,
        "&:hover":{
            background:'#727272',
            opacity:1
        }
    },
    containerDots:{
        position: 'absolute',
        bottom: '10px',
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex'
    },
    dot:{
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        border: '3px solid #f1f1f1',
        margin: '0 5px',
        background: '#f1f1f1',
    },
    dotActive:{
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        border: '3px solid #f1f1f1',
        margin: '0 5px',
        background: '#064B6B',
    }
}));


export default useStyles;