
import { FormatUnderlined } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    contactuslink:{
      color:'#FFE69D',
      textDecoration:'none',
      "&:hover": {
       cursor:'pointer',
       textDecoration:'underline'
       },
    },
    link:{
      "&:hover": {
        fontWeight:800
        },
    }
});

export default useStyles;