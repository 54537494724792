import React, {useState, useEffect, useRef} from 'react';
import { Typography, Box, TabScrollButton, Divider, Container, Avatar, IconButton, Grid, Button, Link } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { navigate } from "gatsby"
import useStyles from './styles';
import Fade from '@mui/material/Fade';
import clsx from 'clsx';
import { GatsbyImage, getImage } from "gatsby-plugin-image";


const Hero = ({pageContext, language}) => {

    const isInitialMount = useRef(true);

    useEffect(() => {   
      if (isInitialMount.current) {
        isInitialMount.current = false;
     } else {
      if(language.english){
        navigate(`/case-studies/${pageContext.title.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s!?]/g,'').split(' ').join('-').toLowerCase()}`)
       }else{
        navigate(`/cas-staideir/${pageContext.acf_case_study.titleIrish.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s!?]/g,'').split(' ').join('-').toLowerCase()}`)
       }
     }
    },[language.english, pageContext.title, pageContext.acf_case_study.titleIrish]);

    const theme = useTheme();
    const classes = useStyles();
    const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
    const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));

    const color1 = pageContext?.acf_case_study?.heroBackgroundColour;
    const topColorPercent = matchesSm ? '82%' :'70%';
    const bottomColorPercent = matchesSm ? '18%' :'30%';

    const [value, setValue] = useState(0);
    const [popup, setPopup] = useState(false);

    function handlePopupOpen(){
        setPopup(true);
    }
    function handlePopupClose(){
      setPopup(false);
  }

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const scrollButton = (props) => {
        return (
        props.direction === "left" ?
        <TabScrollButton orientation="horizontal" direction="left" sx={{color:'white'}} onClick={props.onClick}>
            <ArrowBackIcon  />
        </TabScrollButton> 
        :
        <TabScrollButton orientation="horizontal" direction="right" sx={{color:'white'}} onClick={props.onClick}>
            <ArrowForwardIcon  />
        </TabScrollButton> 
        );
      };

    return (
        <Grid container style={{background: `linear-gradient(to bottom,  ${color1} ${topColorPercent},#FFFFFF ${bottomColorPercent})`}} role="banner" aria-label="hero banner">
            <Container maxWidth="lg">
                <Grid item container pt={8} pb={4}>
                    <Grid item xs={12} sm={3} pt={2}>
                    <Link href={language.english ? "/case-studies" : "/cas-staideir"} color="inherit" style={{textDecoration: 'none'}}>
                        <Button id="main" aria-label={language.english ? "Case studies" : "Cás-Staidéir"} variant="contained" color="inherit">
                            <ArrowBackIcon size="small" />
                            <Typography>{language.english ? 'case studies' : 'Cás-Staidéir'}</Typography>
                        </Button>
                    </Link>
                    </Grid> 
                    <Grid item xs={12} sm={9} pt={matchesSm ? 6 : undefined}>
                        <span lang="ga">
                            <Typography gutterBottom variant="heading" component="h1" style={{lineHeight:'110%'}} fontSize={matchesSm ? '32px' : undefined} color={pageContext.acf_case_study.heroFontColorBlack === true ? "black" : "white"}>
                                {language.english ? pageContext?.title : pageContext.acf_case_study.titleIrish}
                            </Typography>
                        </span>
                       
                        <Typography style={{fontStyle:'italic', fontSize: "24px",fontWeight: 400, lineHeight:matchesSm ? '120%' : undefined}} color={pageContext.acf_case_study.heroFontColorBlack === true ? "black" : "white"}>
                            {language.english ? pageContext?.acf_case_study?.caption : pageContext?.acf_case_study?.captionIrish}
                        </Typography>
                       
                        
                        <Grid container pt={6}>
                        <Grid item xs={4} sm={language.english ? 1.5 : 2.5} pt={1.7}>
                        <Typography color={pageContext.acf_case_study.heroFontColorBlack === true ? "black" : "white"}>
                            {language.english ? 'On this page:' : 'Ar an leathanach seo:'}
                        </Typography>
                        </Grid>
                          <Grid item xs={8} sm={language.english ? 10.5 : 9.5}>
                            {matchesMd ?
                            <Box sx={{ maxWidth: { xs: 210, sm: 480 }}}>
                                <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons
                                indicatorColor="primary"
                                textColor="primary"
                                ScrollButtonComponent={scrollButton}
                                aria-label="scrollable tabs"
                                >
                                     {pageContext?.acf_case_study?.caseContent.map((item, index) => (
                                         <Tab key={index} sx={{textTransform: 'none'}} label={<AnchorLink className={classes.anchor} onAnchorLinkClick={() => document.getElementById(item.title).focus()} to={language.english ? `/case-studies/${pageContext.title.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s!?]/g,'').split(' ').join('-').toLowerCase()}/#${item.title}` : `/cas-staideir/${pageContext.acf_case_study.titleIrish.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s!?]/g,'').split(' ').join('-').toLowerCase()}/#${item.titleIrish}`}><Typography color={pageContext.acf_case_study.heroFontColorBlack === true ? "black" : "#FFE69D"} variant="primary2">{language.english ? item.title : item.titleIrish}</Typography></AnchorLink>} />
                                     ))}
                                </Tabs>
                            </Box> :
                           <>
                           <Box display="flex" mt={1}>
                           {pageContext?.acf_case_study?.caseContent.map((item, index) => (
                           
                                <Box key={index} display="flex">
                                <AnchorLink onAnchorLinkClick={() => document.getElementById(item.title).focus()} to={language.english ? `/case-studies/${pageContext.title.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s!?]/g,'').split(' ').join('-').toLowerCase()}/#${item.title}` : `/cas-staideir/${pageContext.acf_case_study.titleIrish.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s!?]/g,'').split(' ').join('-').toLowerCase()}/#${item.titleIrish}`} className={classes.anchor}>
                                    <Box display="flex" ml={1} mr={1} p={1} className={clsx(classes.anchorlink, { [classes.anchorlinkBlack]: pageContext.acf_case_study.heroFontColorBlack === true })}>
                                        <Typography variant="primary2" color={pageContext.acf_case_study.heroFontColorBlack === true ? "black" : "#FFE69D"}>{language.english ? item.title : item.titleIrish}</Typography>
                                    </Box>
                                </AnchorLink>
                                {pageContext?.acf_case_study?.caseContent.length !== index+1 &&
                                    <Divider orientation="vertical" flexItem sx={{background:pageContext.acf_case_study.heroFontColorBlack === true ? "black" : "#FFE69D"}} />
                                }</Box>
                            ))}
                           </Box>
                            </>}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} pl={2} pr={2}>
                    <Box style={{position:'relative'}}>
                    <Fade in={true}>
                     <Box>
                       <GatsbyImage imgStyle={{maxWidth:'100%'}} image={getImage(pageContext?.acf_case_study?.heroThumbnail?.localFile?.childImageSharp)} alt={language.english ? pageContext?.acf_case_study?.heroThumbnail?.altText : pageContext?.acf_case_study?.heroThumbnail?.altTextIrish?.irishAlt} />
                     </Box>
                    </Fade>
                        {pageContext?.acf_case_study?.heroVideo &&
                        <Box style={{position: 'absolute', right: matchesSm ? '40%' : '48%', left: matchesSm ? '40%' : '48%', bottom: matchesSm ? '35%' : '45%'}}>
                            <Avatar className={classes.play}>
                                <IconButton name="english-toggle" aria-label='play video' component="span" onClick={handlePopupOpen}>
                                    <PlayArrowIcon fontSize="large" style={{color:'white'}}/>
                                </IconButton>
                            </Avatar>
                        </Box>}
                    </Box>
                </Grid>
                {pageContext?.acf_case_study?.heroVideo &&
                <Modal
                    open={popup}
                    onClose={handlePopupClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Fade in={popup}>
                    <Box sx={{position: 
                    'absolute',
                    borderRadius:"10px",
                    top: '45%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width:'75%',
                    height: matchesSm ? '40%' : '80%',
                    outline:'none'
                    }}>
                        <Box display="flex" justifyContent="flex-end" pb={2}>
                          <CloseIcon  style={{cursor: 'pointer', color:'white'}}  onClick={handlePopupClose} size="large" />
                        </Box>
                        <iframe title="video" aria-label='hero video' alt="video" src={`${pageContext?.acf_case_study?.heroVideo}?&autoplay=1`} style={{width:matchesXs ? "102%" : "100%",height:"100%"}} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                    </Box>
                    </Fade>
                </Modal>}
            </Container>
        </Grid>
)}

export default Hero;