import React, {useState, useEffect} from 'react';
import Layout from '../components/layout';
import Hero from '../components/Casestudies/Casestudy/Hero/Hero';
import MainContent from '../components/Casestudies/Casestudy/Maincontent/Maincontent.js';
import ScrollToTop from '../components/ScrollToTop/ScrollToTop';
import LanguageContext from "../context/LanguageContext";
import Seo from '../components/seo';
import CircularProgress from '@mui/material/CircularProgress';

const Page = ({pageContext}) => {

    const [hasMounted, setHasMounted] = useState(false);

    useEffect(() => {
        setHasMounted(true);
    }, []);
  
    if(!hasMounted) return <div style={{ alignItems: "center", display: "flex", justifyContent: "center", height: "100vh", width: "100vw" }}><CircularProgress /></div>;

    return (
    <LanguageContext.Consumer>
        {language => (
    <>
    <Layout>
      
        <Seo title={language.english ? pageContext.title : pageContext.acf_case_study.titleIrish} lang={language.english ? "en" : 'ga'} />
        <Hero pageContext={pageContext} language={language} />
        <MainContent pageContext={pageContext} language={language} />
        
    </Layout>
    <ScrollToTop showBelow={420} />
    </>
    )}
    </LanguageContext.Consumer>
  );
}

export default Page;