import React from 'react';
import useStyles from './styles';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const BtnSlider = ({direction, moveSlide}) => {

    const classes = useStyles();

    return(
        <IconButton focusVisibleClassName={classes.iconButton} id={direction==='next' ? "next direction" : "previous direction"} aria-label={direction==='next' ? "direction button next" : "direction button previous"} className={direction === 'next' ? classes.btnSliderNext : classes.btnSliderPrev} onClick={moveSlide}>
            {direction === 'next' ? <ArrowForwardIcon sx={{color:'white'}} /> : <ArrowBackIcon sx={{color:'white'}} />}
        </IconButton>
    )
}

export default BtnSlider;