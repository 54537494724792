import React from 'react';
import { Grid, Box, Container, Avatar, Divider, Typography } from '@mui/material';
import {Link} from 'gatsby';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import LinkIcon from '@mui/icons-material/Link';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Slider from '../../../Slider/Slider/Slider';
import pin from '../../../../images/pin.png';
import GoogleMapReact from 'google-map-react';
import useStyles from './styles';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import RelevantCaseStudies from '../RelevantCaseStudies/RelevantCaseStudies';

const GoogleMapPin = () => <div style={{marginLeft:'-3.9em', marginTop:'-8.5em'}}><img src={pin} alt="google map pin" /></div>;

const MainContent = ({pageContext, language}) => {

    const classes = useStyles();
    const theme = useTheme();
    const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));

    let images = pageContext?.acf_case_study?.imageMosaic;
  
    const img0 = images[0].localFile.childImageSharp;
    const img1 = images[1].localFile.childImageSharp;
    const img2 = images[2].localFile.childImageSharp;
    const img3 = images[3].localFile.childImageSharp;

    return (
        <Box role="main" aria-label="main content">
        <Container maxWidth="lg">
            <Grid container pt={2} pb={4}>
                <Grid item xs={12} sm={6} display="flex" pl={matchesSm ? 2 : undefined} justifyContent={matchesSm ? undefined : "center"}>
                    <a display="flex" href={pageContext?.acf_case_study?.locationGoogleMapShareLink} aria-label={`${language.english ? pageContext?.acf_case_study?.location :pageContext?.acf_case_study?.locationIrish} on google maps, opens in new tab`} rel="noopener noreferrer" target="_blank" color="inherit" style={{textDecoration: 'none'}}>
                        <Box pl={1} pt={1} display="flex">
                            <Avatar sx={{ bgcolor:'#1BA8B2', height: '40px', width: '40px'}}>
                                <FmdGoodIcon style={{color:'white'}}/>
                            </Avatar>
                            <Box mt={1} ml={1}>
                                <span lang="ga">
                                    <Typography variant="primary2" color="black" className={classes.link}>{language.english ? pageContext?.acf_case_study?.location :pageContext?.acf_case_study?.locationIrish}</Typography>
                                </span>
                            </Box>
                        </Box>
                    </a>
                </Grid>
                <Grid item xs={12} sm={6} display="flex" pl={matchesSm ? 2 : undefined} pt={matchesSm ? 2 : undefined} justifyContent={matchesSm ? undefined : "center"}>
                {pageContext.acf_case_study.websiteUrl &&
                    <a display="flex" href={`http://${pageContext?.acf_case_study?.websiteUrl}`} aria-label={`${pageContext?.acf_case_study?.websiteUrl}, opens in new tab`} rel="noopener noreferrer" target="_blank" color="inherit" style={{textDecoration: 'none'}}>
                        <Box pl={1} pt={1} display="flex">
                        <Avatar sx={{ bgcolor:'#1BA8B2', height: '40px', width: '40px'}}>
                            <LinkIcon style={{color:'white'}}/>
                        </Avatar>
                        <Box pl={1} pt={1}>
                            <Typography variant="primary2" color="black" className={classes.link}>{pageContext?.acf_case_study?.websiteUrl}</Typography>
                            </Box>
                        </Box>
                    </a>
                    }
                </Grid>
                <Grid item xs={12} pt={2} display="flex" justifyContent="center">
                    <Box style={{ width: matchesSm ? '92%':'68%'}}>
                        <Divider sx={{background:'black', borderBottomWidth: 0.1 }} />
                        {pageContext?.acf_case_study?.caseContent.slice(0,3).map((item, index) => (
                            <Box key={index} pt={4}>
                                <Typography variant="bodyheading" tabIndex={"0"} component="h2" id={language.english ? item.title : item.titleIrish} gutterBottom>
                                    {language.english ? item.title : item.titleIrish}
                                </Typography>  
                                <Typography variant="body" style={{fontSize:matchesSm ? '18px' : undefined, lineHeight:matchesSm ? '140%' : undefined}} dangerouslySetInnerHTML={{__html:  language.english ? item.content  : item.contentIrish}} />    
                            </Box>
                        ))}
                    </Box>
                </Grid>
            </Grid>
        </Container>

        <Box>
            <Slider language={language} items={pageContext?.acf_case_study?.imageGallery}/>
        </Box>

        <Container maxWidth="lg">
            <Grid container pb={6}>
                <Grid item xs={12} pt={2} display="flex" justifyContent="center">
                    <Box style={{ width: matchesSm ? '92%':'68%'}}>
                    {pageContext?.acf_case_study?.caseContent.slice(3,4).map((item, index) => (
                            <Box key={index} pt={4}>
                                <Typography variant="bodyheading" component="h2" tabIndex={"0"} id={language.english ? item.title : item.titleIrish} gutterBottom>
                                    {language.english ? item.title : item.titleIrish}
                                </Typography>  
                                <Typography variant="body" style={{fontSize:matchesSm ? '18px' : undefined, lineHeight:matchesSm ? '140%' : undefined}} dangerouslySetInnerHTML={{__html:  language.english ? item.content  : item.contentIrish}} />    
                            </Box>
                        ))}
                    </Box>
                </Grid>
                </Grid>
        </Container>
        <Container maxWidth="xl">
            <Grid container>
                <Grid item xs={12} md={6} display="flex" pt={4} pl={1} justifyContent={matchesSm ? undefined : "center"}>
                    <Box style={{width:matchesSm ? '96.7%' : '98.8%', background:'#2C2C69'}} p={matchesSm ? 3 : 6} mr={1}>
                    {pageContext?.acf_case_study?.caseContent.slice(4,5).map((item, index) => (
                            <Box key={index}>
                                <Typography variant="bodyheading" tabIndex={"0"} component="h2" color="#CEBF89" style={{lineHeight:'64px'}} id={language.english ? item.title : item.titleIrish} gutterBottom>
                                    {language.english ? item.title : item.titleIrish}
                                </Typography>  
                                <Typography variant="body" style={{fontSize:matchesSm ? '18px' : undefined, lineHeight:matchesSm ? '140%' : undefined}} color="white" dangerouslySetInnerHTML={{__html:  language.english ? item.content  : item.contentIrish}} />    
                            </Box>
                        ))}
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} display="flex" pt={matchesSm ? 2 : 4} pr={matchesSm ? undefined : 1} justifyContent={matchesSm ? undefined : "center"}>
                <Box style={{width:matchesSm ? '94.7%' : '99%', background:'#004D44'}} p={matchesSm ? 3 : 7} ml={1}>
                    <Grid container>
                        <Grid item md={12} lg={5}>
                        <Typography variant="bodyheading" component="h2" color="#CEBF89">
                            {language.english ? 'Contact Us' : 'Glaoigh Orainn'}
                        </Typography> 
                        <Box mt={2} style={{height:'140px'}}>
                          
                        <GatsbyImage style={{borderRadius: '50%', width:'150px', height:'150px'}} image={getImage(pageContext?.acf_case_study?.contact[0].contactImage?.localFile?.childImageSharp)} alt={language.english ? pageContext?.acf_case_study?.contact[0]?.contactImage?.altText : pageContext?.acf_case_study?.contact[0]?.contactImage?.altTextIrish?.irishAlt}/>
                        </Box>
                        </Grid>
                        <Grid item mt={matchesSm ? 4 : 6} md={12} lg={7} pr={6}>

                           
                            {language.english ? 
                            <Typography variant="body" color="white">Contact <span lang="ga"><a href={`mailto:${pageContext?.acf_case_study?.contact[0]?.contactEmail}`} className={classes.contactuslink}>{pageContext?.acf_case_study?.contact[0].contactName}</a></span> to find out how we can help you. 
                            <br />
                            <br />
                            Or visit the <Link to="/how-we-can-help" className={classes.contactuslink}>how we can help you</Link> page.</Typography>
                            :
                            <Typography variant="body" color="white">Déan teagmháil le <a href={`mailto:${pageContext?.acf_case_study?.contact[0]?.contactEmail}`} className={classes.contactuslink}>{pageContext?.acf_case_study?.contact[0].contactName}</a> le fáil amach conas is féidir linn cabhrú leat. 
                            <br />
                            <br />
                            Nó tabhair cuairt ar an <Link to="/how-we-can-help" className={classes.contactuslink}>leathanach conas is féidir linn cabhrú</Link> leat.</Typography>}
                            
                        </Grid>
                    </Grid>
                </Box>
                </Grid>
            </Grid>
            
             <Grid container ml={matchesSm ? 0 : undefined} mr={matchesSm ? 2 : 2.5 } mt={matchesSm ? 2 : 1.5}>
                <Grid item xs={12} sm={4}>
                    <Box p={1} style={{height:'100%'}}>
                        <GatsbyImage style={{width:matchesSm ? '100%' : '100%',height:'100%'}} image={getImage(img0)} alt={language.english ? pageContext?.acf_case_study?.imageMosaic[0]?.altText : pageContext?.acf_case_study?.imageMosaic[0]?.altTextIrish?.irishAlt} />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Box p={1} style={{height:'100%'}}>
                        <GatsbyImage style={{width:'100%', height:'100%'}} image={getImage(img1)} alt={language.english ? pageContext?.acf_case_study?.imageMosaic[1]?.altText : pageContext?.acf_case_study?.imageMosaic[1]?.altTextIrish?.irishAlt}/>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Box p={1} style={{height:'100%'}}>
                       
                        <GatsbyImage style={{width:'100%', height:'100%'}} image={getImage(img2)} alt={language.english ? pageContext?.acf_case_study?.imageMosaic[2]?.altText : pageContext?.acf_case_study?.imageMosaic[2]?.altTextIrish?.irishAlt} />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box p={1} style={{height:'100%'}}>
                        <GatsbyImage style={{width:matchesSm ? '100%' : '100%', height:'100%'}} image={getImage(img3)} alt={language.english ? pageContext?.acf_case_study?.imageMosaic[3]?.altText : pageContext?.acf_case_study?.imageMosaic[3]?.altTextIrish?.irishAlt} />
                    </Box>
                </Grid>
            </Grid> 

            <Container maxWidth="lg">
            <Grid container pb={6}>
                <Grid item xs={12} pt={2} display="flex" justifyContent="center">
                    <Box style={{ width: matchesSm ? '92%':'68%'}}>
                    {pageContext?.acf_case_study?.caseContent.slice(5).map((item, index) => (
                            <Box key={index} pt={4}>
                                <Typography variant="bodyheading" component="h2" id={language.english ? item.title : item.titleIrish} gutterBottom>
                                    {language.english ? item.title : item.titleIrish}
                                </Typography>  
                                <Typography variant="body" style={{fontSize:matchesSm ? '18px' : undefined, lineHeight:matchesSm ? '140%' : undefined}} dangerouslySetInnerHTML={{__html:  language.english ? item.content  : item.contentIrish}} />    
                            </Box>
                        ))}
                    </Box>
                </Grid>
                </Grid>
        </Container>
            
        <Grid item xs={12} pl={1} pr={1} pb={8}>
            <div style={{ height: '80vh', width: '100%' }}>
                <GoogleMapReact
                bootstrapURLKeys={{ key: `${process.env.GATSBY_GOOGLE_API_KEY}` }}
                defaultCenter={{lat: pageContext?.acf_case_study?.googleMap?.latitude, lng: pageContext?.acf_case_study?.googleMap?.longitude }}
                defaultZoom={14}
                >
                <GoogleMapPin
                    lat={pageContext?.acf_case_study?.googleMap?.latitude}
                    lng={pageContext?.acf_case_study?.googleMap?.longitude}
                    text="My Marker"
                />
                </GoogleMapReact>
            </div>
        </Grid>

    </Container>
   
        <RelevantCaseStudies 
            language={language}
            relevantCaseStudies={pageContext.acf_case_study.relevantCaseStudies}
        />
        </Box>
    )
}

export default MainContent;



