import React from 'react';
import {Grid, Typography, Box, Container, Card, CardMedia, CardContent } from '@mui/material';
import useStyles from './styles';
import {Link} from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const RelevantCaseStudies = ({relevantCaseStudies, language}) => {


    const classes = useStyles();
  
    return(
        relevantCaseStudies !== null ?
        <Grid container style={{backgroundColor:'#CEBF89'}}>
        <Container maxWidth="xl" role="complementary" aria-label="relevant case studies">
            <Grid item xs={12} mb={8}>
              <Grid container >
                <Grid item xs={12} mt={4} mb={3} display="flex" justifyContent="center">
                    <Box>
                     <Typography variant="bodyheading" component="h2">{language.english ? 'Relevant Case Studies' : 'Cás-Staidéir ábhartha'}</Typography>
                    </Box>
                </Grid>
       
                {relevantCaseStudies.map((item, index) => (

                    <Grid key={index} item xs={12} sm={6} md={4} lg={3} p={2} pl={2} pr={2} pb={4}>
                         
                    <Link to={language.english ? `/case-studies/${item?.title.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s!?]/g,'').split(' ').join('-').toLowerCase()}` : `/cas-staideir/${item?.acf_case_study.titleIrish.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s!?]/g,'').split(' ').join('-').toLowerCase()}`} color="inherit" style={{textDecoration: 'none'}}>
                        <Card className={classes.card1}>
                        <CardMedia height="140"><GatsbyImage style={{height:'200px'}} imgStyle={{borderRadius:'15px 15px 0px 0px'}} image={getImage(item.acf_case_study?.heroThumbnail?.localFile?.childImageSharp)} alt=""/></CardMedia>
                        <Box p={1}>
                        <CardContent>
                            <span lang="ga">
                            <Typography gutterBottom variant="cardheading2" component="h3">
                            {language.english ? item.title : item.acf_case_study.titleIrish}
                            </Typography>
                            </span>
                            <Typography gutterBottom variant="card3">
                            {language.english ? item.acf_case_study.caption : item.acf_case_study.captionIrish}
                            </Typography>
                        </CardContent>
                        </Box>
                        </Card>
                    </Link>
                    </Grid>
                ))}
            </Grid>
          </Grid>
          </Container>
        </Grid>: null
                    
   )
}

export default RelevantCaseStudies;